.navigation-clean {
  background:#fff;
  background-image: url("https://www.transparenttextures.com/patterns/dark-denim-3.png");
  /*background-image: url("https://www.transparenttextures.com/patterns/asfalt-dark.png");*/
	position: fixed;
	top: 0px;
	width: 100%;
  padding-top:.75rem;
  padding-bottom:.75rem;
  color:#333;
  border-radius:0;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 10px, rgba(0, 0, 0, 0.23) 0px 3px 3px;
  border:none;
  margin-bottom:0;
  background-color: var(--themeblue);
  z-index: 1234567;
  transition: transform 0.5s ease-in-out;
  transform: translateY(0);
}

@media (min-width:768px) {
  /*
  .navigation-clean {
    padding-top:1rem;
    padding-bottom:1rem;

  } */
  .make-space {
		height: 100px;
	}
}

.navigation-clean .navbar-brand {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight:bold;
  color:#ffffff;
}

.navigation-clean .navbar-brand:hover {
  color:#ffffff;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
 .navbar-light .navbar-brand:hover {
   color: #fff;
 }


.navigation-clean .navbar-toggler {
  border-color:#ddd;
}

.navigation-clean .navbar-toggler:hover, .navigation-clean .navbar-toggler:focus {
  background:none;
}

.navigation-clean .navbar-toggler {
  color:#888;
}

.navigation-clean .navbar-collapse, .navigation-clean .form-inline {
  border-top-color:#ddd;
}

.navigation-clean.navbar .navbar-nav .nav-link {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  padding-left:18px;
  padding-right:18px;
  color:#ffffff;
  text-shadow: 0px 1px 1px rgba(0,0,0,0.5);
  -webkit-text-shadow: 0px 1px 1px rgba(0,0,0,0.5);
	transition: all 0.3s ease-in-out;
}


.navigation-clean.navbar-light .navbar-nav .nav-link:hover {
		transition: all 0.2s ease-in-out;
		transform: scale(1.2);

}


.navigation-clean .navbar-nav > li > .dropdown-menu {
  margin-top:-5px;
  box-shadow:none;
  background-color:#fff;
  border-radius:2px;
}

.navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item {
  line-height:2;
  color:#37434d;
}

.navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item:hover {
  background:#eee;
  color:inherit;
}

.active {
	transition: border 0.3s ease-in-out;
	border: 1px solid white;
  background-color:transparent;
}

.hidenav {
  transition: 1s ease-in-out;
  transform: translateY(-100%);
}

@media (min-width:300px) {
  #tagline {
    display: block;
  }
}



