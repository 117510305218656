@font-face {
  font-family: "Roboto Condensed";
  src: local('Roboto Condensed Regular'), local('RobotoCondensed-Regular'), url("./assets/fonts/RobotoCondensed-Regular.woff") format("woff"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Condensed";

  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url("./assets/fonts/RobotoCondensed-Bold.woff") format("woff"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Condensed";

  src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url("./assets/fonts/RobotoCondensed-Light.woff") format("woff"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto Condensed', sans-serif;
  background: #ffffff;  /* fallback for old browsers */
  background: -webkit-linear-gradient(200deg, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(200deg, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}