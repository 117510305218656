.portfolio {
  color:#313437;
  background-color:#fff;
}


.portfolio p {
  color:#7d8285;
}

@media (max-width:767px) {
  .portfolio h2 {
    margin-bottom:25px;
    padding-top:25px;
    font-size:24px;
  }
}

.portfolio .intro {
  font-size:16px;
  max-width:500px;
  margin:0 auto 40px;
}

.portfolio .intro p {
  margin-bottom:0;
}

.portfolio .photos {
  padding-bottom:20px;
}

.portfolio .item {
  padding-bottom:30px;
}

.portfolio .articles {
  padding-bottom:40px;
}

.portfolio .item {
  padding-top:50px;
  min-height:425px;
  text-align:center;
}

.portfolio .item .name {
  font-weight:bold;
  font-size:16px;
  margin-top:20px;
  color:inherit;
}

.portfolio .item .description {
  font-size:14px;
  margin-top:15px;
  margin-bottom:0;
}

.portfolio .item .action {
  font-size:24px;
  width:24px;
  margin:22px auto 0;
  line-height:1;
  display:block;
  color:#4f86c3;
  opacity:0.85;
  transition:opacity 0.2s;
  text-decoration:none;
}

.item .action:hover {
  opacity:1;
}

.image-portfolio {
  position: relative;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.item img {
  transition: all .5s ease-in-out;
}

.img-hover {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  background-color: rgba(33,140,182,0.3);
  transform: scale(1.1);
  filter: blur(.25px);
}


.overlay {
  position: absolute;
  visibility: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(33,140,182,0.4);
  transition: all .5s ease-in-out;
}

.overlay-hover {
  visibility: visible;
  transform: scale(1.1);
  transition: all .5s ease-in-out;
}

.text-image-portfolio {
  color: white;
  font-size: 1em;
  border: 1px solid white;
  padding: 10px;
  position: absolute;
  bottom: 10%;
  right: 5%;
  text-align: center;
  background-color: transparent;
  text-transform: uppercase;
}

.repolistitem {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-radius: 3px;
}
  .repolistitem:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    -moz-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: rotate(0deg);
    perspective: 1000px;
  }

  @keyframes shake {
    10%, 90% {
      transform: rotate(-0.5deg);
    }
    20%, 80% {
      transform: rotate(0.5deg);
    }
    30%, 50%, 70% {
      transform: rotate(-1deg);
    }
    40%, 60% {
      transform: rotate(1deg);
    }
  }

.repo-title {
  font-weight: bold;
  font-size: 18px;
}

.repo-description {
  font-size: 16px;
  color: #7d8285;
}